import * as React from 'react';
import { IndexLayout } from '@app/app-components/layout';
import { SignInWithGoogle } from '@app/app-components/sign-in-with-google';

import styled from 'styled-components';
import { Typography, Container } from '@material-ui/core';

import SheetbestLogo from '@app/public/images/logo.svg';

const Section = styled.div`
  ${(props: any) => props.theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;

const SectionTitle = styled(Typography)`
  color: ${props => props.theme.palette.primary.main};
  font-weight: 300;
  font-size: 1.5rem;
`;

const SectionSubtitle = styled(Typography)`
  margin-bottom: ${props => props.theme.spacing(1)}px;
  color: ${props => props.theme.palette.text.secondary};
`;

const SectionTitleContainer = styled(Container)`
  justify-content: center;
  text-align: center;
  margin-bottom: ${props => props.theme.spacing(4)}px;
`;

const SectionLogo = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spacing(4)}px;

  ${(props: any) => props.theme.breakpoints.down('sm')} {
    padding: 0 0 ${props => props.theme.spacing(4)}px 0;
  }
`;

const Home: React.FC = () => {
  return (
    <IndexLayout>
      <Container fixed maxWidth="md">
        <Section>
          <SectionTitleContainer>
            <SectionLogo>
              <SheetbestLogo height={45} />
            </SectionLogo>
            <SectionTitle>Welcome to Sheet Best</SectionTitle>
            <SectionSubtitle>The best backend you’ll never code.</SectionSubtitle>
          </SectionTitleContainer>
          <SignInWithGoogle />
        </Section>
      </Container>
    </IndexLayout>
  );
};

export default Home;
