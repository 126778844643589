import * as React from 'react';
import { Container, Link } from '@material-ui/core';
import styled from 'styled-components';

import { SpacedText } from '@app/styled-components/common';
import { StartButton } from './start-button';
import { useMeQuery } from '@sheet-best/core/lib/graphql/hooks';

const SignInContainer = styled.div`
  padding-top: ${props => props.theme.spacing(2)}px;
  padding-bottom: ${props => props.theme.spacing(2)}px;

  ${(props: any) => props.theme.breakpoints.down('xs')} {
    padding-top: ${props => props.theme.spacing(1)}px;
    padding-botton: ${props => props.theme.spacing(1)}px;
  }
`;

const SignInContent = styled.div``;

const SignInDescription = styled(SpacedText)`
  ${(props: any) => props.theme.breakpoints.down('xs')} {
    padding: ${props => props.theme.spacing(2, 1)};
  }
  padding: ${props => props.theme.spacing(4, 2)};
  font-size: 0.9rem;
  line-height: 1.5;
  text-align: center;
  color: ${props => props.theme.palette.text.secondary};
`;

const SingInTourLink = styled(Link)``;

const SignInActions = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const SignInWithGoogle: React.FC = () => {
  const meQ = useMeQuery();
  return (
    <SignInContainer>
      <Container fixed maxWidth="sm">
        <SignInContent>
          <SignInActions>
            <StartButton goToDashboard={meQ.data?.me?.id != null}>Sign in with Google</StartButton>
          </SignInActions>
          <SignInDescription>
            Turn your spreadsheets into REST APIs. Build websites, widgets, apps, prototypes, and
            tons more. Leave the backend to us.
            <br />
            For more information checkout the{' '}
            <SingInTourLink href="https://sheet.best#features">main features</SingInTourLink>.
          </SignInDescription>
        </SignInContent>
      </Container>
    </SignInContainer>
  );
};
