import * as React from 'react';

import { UserType } from '@sheet-best/core/lib/graphql/hooks';
import { LayoutMain } from '@app/styled-components/common';
import { Footer } from './footer';

interface LayoutProps {
  me?: UserType;
}

export const IndexLayout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <>
      <LayoutMain>{children}</LayoutMain>
      <Footer />
    </>
  );
};
