import { MeDocument, MeQuery } from '@sheet-best/core/lib/graphql/hooks';
import Router from 'next/router';
import { GetServerSidePropsContext } from 'next';
import { ApolloClient } from '@apollo/client';

const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || '';
const APP_DOMAIN = process.env.NEXT_PUBLIC_APP_DOMAIN || '';

export const auth_endpoint = (next: String) => {
  return `${API_BASE_URL}/oauth/login/google-oauth2?next=${APP_DOMAIN + next}`;
};

export const OAUTH_ENDPOINT = auth_endpoint('/admin');

export const auth = async (apolloClient: ApolloClient<any>, ctx: GetServerSidePropsContext) => {
  try {
    const meRes = await apolloClient.query<MeQuery>({ query: MeDocument });
    if (meRes.data.me == null) {
      throw new Error('Not authorized');
    }
    return meRes.data?.me;
  } catch (e) {
    console.error(e);
    if (ctx.res != null) {
      ctx.res.writeHead(302, { location: '/' });
      ctx.res.end();
    } else {
      Router.push('/');
    }
    return null;
  }
};
