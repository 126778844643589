import styled from 'styled-components';
import ReportProblem from '@material-ui/icons/ReportProblem';
import { Button, Typography } from '@material-ui/core';
import OptimizedImage from '@0soft/optimized-image';

export const spacing = (n: number) => `${n * 0.5}rem`;

export const UndecoratedLink = styled.a`
  text-decoration: none;
  color: inherit;
  :hover {
    cursor: pointer;
  }
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: ${spacing(1)};
  margin-bottom: ${spacing(2)};
  border-bottom: thin solid rgba(0, 0, 0, 0.87);
  h1 {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const ReportProblemIcon = styled(ReportProblem)`
  color: red;
`;

export const Uppercase = styled.span`
  text-transform: uppercase;
`;

export const SuccessButton = styled(Button)`
  background-color: ${props => props.theme.palette.success.main};
  color: ${props => props.theme.palette.success.contrastText};
  &:hover {
    background-color: ${props => props.theme.palette.success.dark};
  }
`;

export const PositiveButton = styled(Button)`
  color: ${props => props.theme.palette.success.main};
`;

export const WhiteMenuButton = styled(Button)`
  color: white;
`;

export const Logo = styled.div.attrs((props: any) => ({
  width: props.width || '187.95px',
  height: props.height || '28px',
}))`
  width: ${props => props.width};
  height: ${props => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.theme.spacing(1, 0)};
`;

export const LayoutMain = styled.main`
  min-height: calc(100vh - 23.05rem);
  margin-top: ${props => props.theme.spacing(8)}px;
`;

export const ResponsiveImage = styled(OptimizedImage)`
  max-width: 100%;
  img {
    max-width: 100%;
  }
`;

export const SpacedTitle = styled(Typography)`
  line-height: 1.5;
`;

export const SpacedText = styled(Typography)`
  line-height: 1.8;
`;

export const Anchor = styled.a``;
