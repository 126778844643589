import * as React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { UndecoratedLink } from '@0soft/zero-web';
import Link from 'next/link';
import { OAUTH_ENDPOINT } from '@app/lib/auth/auth';

interface StartButtonProps {
  buttonProps?: ButtonProps;
  className?: string;
  goToDashboard: boolean;
}

export const StartButton: React.FC<StartButtonProps> = ({
  children,
  buttonProps,
  className,
  goToDashboard,
}) => {
  const btn = (
    <Button variant="contained" color="primary" size="large" {...buttonProps} className={className}>
      {children}
    </Button>
  );
  return goToDashboard ? (
    <Link href="/admin" passHref>
      <UndecoratedLink>{btn}</UndecoratedLink>
    </Link>
  ) : (
    <UndecoratedLink href={OAUTH_ENDPOINT}>{btn}</UndecoratedLink>
  );
};
