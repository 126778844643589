import * as React from 'react';
import styled from 'styled-components';
import { Typography, Container, Link } from '@material-ui/core';

const FooterContainer = styled.footer`
  padding: ${props => props.theme.spacing(4, 0)};
  text-align: center;
`;

const FooterCopyright = styled.div`
  margin: ${props => props.theme.spacing(2, 0)};
`;

interface FooterProps {
  isAdmin?: boolean;
}

export const Footer: React.FunctionComponent<FooterProps> = () => {
  return (
    <FooterContainer>
      <Container>
        <FooterCopyright>
          <Typography>
            Copyright 2020-{new Date().toLocaleDateString('en', { year: '2-digit' })} ©{' '}
            <Link href="https://sheet.best/">SheetBest</Link>
          </Typography>
        </FooterCopyright>
      </Container>
    </FooterContainer>
  );
};
